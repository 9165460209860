@import '~@vismaux/vud/dist/css/vud.min.css';

:root {
    font-synthesis: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
    --neutral-90: #252626;
    --neutral-80: #494a4a;
    --neutral-70: #6b6b6b;
    --neutral-60: #8a8a8a;
    --neutral-50: #a5a5a5;
    --neutral-40: #c0c0c0;
    --neutral-30: #dadada;
    --neutral-20: #f2f2f2;
    --neutral-10: #f7f7f7;
    --neutral-05: #ffffff;
    --spcs-coral-90: #66251f;
    --spcs-coral-80: #b23124;
    --spcs-coral-70: #cc4b3d;
    --spcs-coral-60: #f56262;
    --spcs-coral-50: #fe796b;
    --spcs-coral-40: #fe9489;
    --spcs-coral-30: #feaba3;
    --spcs-coral-20: #f5ceca;
    --spcs-coral-10: #f7e0de;
    --spcs-coral-05: #f7eceb;
    --spcs-purple-90: #2f1b4c;
    --spcs-purple-80: #48237d;
    --spcs-purple-70: #6431ac;
    --spcs-purple-60: #8049cc;
    --spcs-purple-50: #9263d6;
    --spcs-purple-40: #a57fdb;
    --spcs-purple-30: #ba95f0;
    --spcs-purple-20: #ceaffa;
    --spcs-purple-10: #e3d2fa;
    --spcs-purple-05: #eee7f9;
    --green-90: #254934;
    --green-80: #316245;
    --green-70: #2d7048;
    --green-60: #4e9c54;
    --green-50: #67a870;
    --green-40: #7faf7f;
    --green-30: #9bc29b;
    --green-20: #afd4af;
    --green-10: #cfe7cf;
    --green-05: #ebf7eb;
}

body {
    margin: 0;
    overscroll-behavior: none;
    padding-top: 0;
}

html,
body,
#root,
#root > .app-container {
    height: 100%;
}

p,
span,
a,
h1,
h2,
h3,
h4,
h5,
h6,
li,
button,
label,
input,
textarea,
select {
    font-family: 'Open Sans', sans-serif;
}

::-webkit-scrollbar {
    width: 0.4rem;
}

::-webkit-scrollbar-thumb {
    border-radius: 12px;
    visibility: 'hidden';
}
